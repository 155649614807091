import React, {useEffect} from 'react';
import Head from 'next/head';
import { loadCookie } from '../cookie/cookieSlice';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import * as gtag from '../cookie/lib/gtag';


const ConsoHead = (props) => {
    
    const {meta, seo} = props;
    const {title, description, canonical} = seo;
    const cookieState = useSelector(state => state.cookie);
    const router = useRouter();

    useEffect(() => {
        
        const handleRouteChange = (url) => {
          if ( cookieState.categories.analytics == 1) {
            gtag.pageview(url);
          }
        }
        router.events.on('routeChangeComplete', handleRouteChange);
       
        return () => {
          router.events.off('routeChangeComplete', handleRouteChange)
        }
      }, [router.events]
    )

    useEffect( ()=>{

      if (cookieState.status == 'init') {
        loadCookie();
      }
      }, []
    )

    useEffect( ()=>{
      if (cookieState.status == 'success' && cookieState.categories.analytics == 0) {
        window[`ga-disable-${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`] = true;

      }
    }, [cookieState])

  
    const socialTags = meta ?
                        <>
                          <meta property="og:type" content={meta.type} />
                          <meta property="og:url" content={canonical} />
                          
                        </> 
                        :
                        <meta property="og:type" content="website" /> ;

  const imageTags = (meta && meta.hasOwnProperty('image') && meta.image ) && 
                    <>
                      <meta property="og:image" content={meta.image} />
                      <meta property="og:image:height" content={meta.imageSize.height} />
                      <meta property="og:image:width" content={meta.imageSize.width} />
                    </>;

  const noIndex = (meta && meta.hasOwnProperty('noindex') && meta.noindex ) && <meta name="robots" content="noindex" />;

 
  const gtagScript = 
        `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date()); 
        gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {page_path: window.location.pathname,});`;


  return (
          <Head>
            <title>{title} | Conso</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name="description" content={description}></meta>
            <link rel="canonical" href={canonical} />
            
            <meta property="og:title" content={title} />
            {socialTags}
            {imageTags}
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content="Conso" />
            {noIndex}
            
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <script  dangerouslySetInnerHTML={{__html: gtagScript }}></script>

          </Head> 
    )
}

export default ConsoHead;
