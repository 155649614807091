export var GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS; // https://developers.google.com/analytics/devguides/collection/gtagjs/pages

export var pageview = function pageview(url) {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  });
}; // https://developers.google.com/analytics/devguides/collection/gtagjs/events

export var event = function event(action, payload) {
  window.gtag('event', action, payload);
};